import React, { useContext } from 'react';
import dayjs from 'dayjs';
import { formatPhone } from '../../common/helpers/format';
import PillBox from '../../common/form/PillBox';
import ListItem from '../../common/list/ListItem';
import { Contact } from '../../types/Contact.type';
import { MeContext } from '../../common/contexts/me.context';

interface Props {
  row: Contact;
  rowKey: number;
  setSelectedContact: React.Dispatch<React.SetStateAction<Contact | null>>;  // Use the prop here
  markContacted: (contactId: number) => void;
  markingAsContacted: number | undefined;
}

const ContactItem: React.FC<Props> = ({
  row,
  rowKey,
  setSelectedContact,  
  markContacted,
  markingAsContacted,
}) => {
  
  const { me } = useContext(MeContext);

  const handleClick = () => {
    setSelectedContact(row);  
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleClick(); 
    }
  };

  return (
    <div
      role="button"
      tabIndex={0}  
      className="cursor-pointer relative"
      onClick={handleClick}
      onKeyDown={handleKeyDown}  
    >
      <ListItem index={rowKey}>
        {row.message && row.message.includes("New Lead") && (
          <div className="grid grid-cols-1 sm:grid-cols-1 md:flex items-center justify-between space-x-4">						
            <div className="sm:flex flex-col flex-shrink-0 items-end space-y-3">
              <p className="flex items-center space-x-4">
                <span className="relative text-sm leading-4 text-gray-500 hover:text-gray-900 font-medium">
                  &nbsp;
                </span>
              </p>
              <PillBox
                className={'bg-primary-600 focus:ring-primary-500 shadow-sm'}
                text={`3D Dream Builder Quote Request`}
                onClick={() => {}}
              />
            </div>
          </div>
        )}
        <div className="grid grid-cols-1 sm:grid-cols-1 md:flex items-center justify-between space-x-4">						
          <div className="min-w-0">
            <div className="relative group flex items-center space-x-4">
              <div className="space-y-3">
                {me.role === 'superAdmin' && row.accountName && (
                  <div className="flex items-center space-x-3">
                    <span className="block">
                      <h2 className="text-sm font-medium leading-4">
                        <span className="absolute inset-0" />
                        {row.accountName}
                      </h2>
                    </span>
                  </div>
                )}
                <div className="text-sm leading-4 text-gray-500 group-hover:text-gray-900 font-medium">
                  {me.role === 'superAdmin' && row.message && row.message.includes("New Lead") && (
                    <>
                      {row.accountEmail && (
                        <span className="sm:whitespace-nowrap block sm:inline">
                          {row.accountEmail}
                        </span>
                      )}

                    </>
                  )}
                </div>
                {!(me.role === 'superAdmin' && row.message && row.message.includes("New Lead")) && (
                    <>
                <div className="flex items-center space-x-3">
                  <span className="block">
                    <h2 className="text-sm font-medium leading-4">
                      <span className="absolute inset-0" />
                      {row.name}
                    </h2>
                  </span>
                </div>
                <div className="text-sm leading-4 text-gray-500 group-hover:text-gray-900 font-medium">
                  {row.email && (
                    <span className="sm:whitespace-nowrap block sm:inline">
                      {row.email}
                    </span>
                  )}
                  {row.email && row.phone && (
                    <span className="hidden sm:inline mx-1">/</span>
                  )}
                  {row.phone && (
                    <span className="sm:whitespace-nowrap block sm:inline">
                      {formatPhone(row.phone)}
                    </span>
                  )}
                </div>
                </>
                )}
              </div>
            </div>
          </div>
          <div className="sm:flex flex-col flex-shrink-0 items-end space-y-3">
            <PillBox
              className={
                row.contacted
                  ? 'bg-primary-600 cursor-none focus:ring-primary-500 shadow-sm'
                  : 'bg-gray-600 hover:bg-gray-700 focus:ring-gray-500 shadow-sm'
              }
              text={
                row.contacted
                  ? `Contacted by ${row.contacted}`
                  : 'Mark as contacted'
              }
              onClick={() => {
                if (!row.contacted) {
                  markContacted(row.id);
                }
              }}
              loading={markingAsContacted === row.id}
            />
            <p className="flex text-gray-500 text-sm leading-4 space-x-2">
              Sent {dayjs.utc(row.createDate).local().format('MM-DD-YYYY')}{' '}
              at {dayjs.utc(row.createDate).local().format('hh:mma')}
            </p>
          </div>
        </div>
      </ListItem>
    </div>
  );
};

export default ContactItem;
