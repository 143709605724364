import { API } from 'aws-amplify';
import { createUpload } from '@mux/upchunk';

const uploadService = () => {
	const getUploadUrl = async (
		accountId: number,
		contentType: string,
		extension: string,
		name: string,
		video: boolean = false,
		specialType?: string,
		baseURL?: string,
		imageCount?: number
	) => {
		return API.post('api', `/${accountId}/upload`, {
			body: {
				contentType,
				extension,
				name,
				video,
				specialType,
				baseURL,
				imageCount
			},
		});
	};

	const getPlaybackId = async (id: string) => {
		return API.get('api', `/mux/${id}`, {});
	};

	const getPlaybackIdLoop = async (id: string) => {
		return new Promise((resolve, reject) => {
			let attempt = 0;
			const interval = setInterval(async () => {
				attempt++;
				try {
					const response = await getPlaybackId(id);
					if (response) {
						clearInterval(interval);
						resolve(response.playbackId);
					} else if (attempt === 10) {
						clearInterval(interval);
						reject();
					}
				} catch (e: any) {
					if (e.response && e?.response?.status !== 404) {
						clearInterval(interval);
						reject(e);
					}
				}
			}, 1000);
		});
	};

	const uploadFile = async (
		file: any,
		payload: { url: string; fields: any }
	) => {
		const formData: FormData = new FormData();
		Object.entries(payload.fields).forEach(([k, v]: any) => {
			formData.append(k, v);
		});
		formData.append('file', file); // must be the last one

		const resp = await fetch(payload.url, {
			method: 'POST',
			body: formData,
		});

		return resp;
	};

	const uploadVideoFile = async (url: string, file: any, video = false) => {
		return new Promise((resolve, reject) => {
			const upload = createUpload({
				endpoint: url,
				file: file,
				chunkSize: 5120,
			});

			upload.on('error', (err) => {
				console.error('🙀', err.detail);
				reject(err);
			});
			upload.on('success', (res) => {
				resolve(res);
			});
		});
	};

	const upload = async (
		accountId = -1,
		file: any,
		type: string,
		video: boolean = false,
		specialType?: string,
		baseURL?: string,
		imageCount?: number
	): Promise<any> => {
		const uploadUrlRes: any = await getUploadUrl(
			accountId,
			type,
			file.extension,
			file.filename || file.name,
			video,
			specialType,
			baseURL,
			imageCount
		);

		if (uploadUrlRes === 'You may only upload one image per listing.') {
			return 'limitReached';
		}
		if (video) {
			await uploadVideoFile(uploadUrlRes.url, file, video);
			const id = uploadUrlRes.id;
			return getPlaybackIdLoop(id);
		} else {
			const key = uploadUrlRes.key;
			const urlFirstPart = uploadUrlRes.url.url;
			const uploadUrl = `${urlFirstPart}${key}`;
			await uploadFile(file, uploadUrlRes.url);
			return uploadUrl;
		}
	};

	return {
		upload,
	};
};

export default uploadService();
