import {
	closestCorners,
	DndContext,
	DragEndEvent,
	MouseSensor,
	TouchSensor,
	useDroppable,
	useSensor,
	useSensors,
} from '@dnd-kit/core';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import { LinkIcon, PencilIcon } from '@heroicons/react/outline';
import { fromImage } from '../common/helpers/Imtool.index';
import React, { useContext, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { v4 } from 'uuid';
import { ProductImage, UploadStatus } from '../pages/products/Product.type';
import uploadService from '../services/upload.service';
import { MeContext } from './contexts/me.context';
import { FieldErrors } from './form/FieldErrors';
import { Input } from './form/Input';
import ImageUploaderImageSortable from './ImageUploaderImageSortable';
import Modal from './Modal';
import { OpenClosedStates } from './OpenClosedStates';
import { FormError } from './types/ErrorResponse.type';

function DroppableContainer({
	children,
	id,
}: {
	children: React.ReactNode;
	id: string;
	getStyle?: ({
		isOverContainer,
	}: {
		isOverContainer: boolean;
	}) => React.CSSProperties;
}) {
	const { setNodeRef } = useDroppable({
		id,
	});

	return (
		<div
			ref={setNodeRef}
			className="col-span-6 grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5 gap-4"
			style={{
				minHeight: 110,
			}}
		>
			{children}
		</div>
	);
}

type Props = {
	value: ProductImage[];
	onChange: (val: ProductImage[]) => void;
	errors?: FormError;
	description: string;
};

const AdImageUploader: React.FC<Props> = ({
	value,
	onChange,
	errors,
	description,
}) => {
	const { me } = useContext(MeContext);
	const [openEditLinkModal, setOpenEditLinkModal] = useState(
		OpenClosedStates.Closed
	);
	const [editingData, setEditingData] = useState<{
		index: number;
		value: string;
	} | null>(null);
	const { getRootProps, getInputProps } = useDropzone({
		accept: {
			'image/*': [],
		},
		onDrop: async (acceptedFiles: File[]) => {
			const images = acceptedFiles.filter((file) =>
				file.type.startsWith('image/')
			);
			let imagesResized: any[] = [];
			if (images?.length) {
				imagesResized = await processImages(images);
				onChange([...value, ...imagesResized]);
				for (const file of imagesResized) {
					try {
						await uploadService.upload(
							me?.account.id,
							file,
							file.type,
							false,
							'displayAd',
							'ads/display'
						);
						file.uploadStatus = UploadStatus.complete;
					} catch (err) {
						file.uploadStatus = UploadStatus.failed;
					}
				}
			}
			onChange([
				...value,
				...imagesResized.map((file) => ({
					...file,
					url: `${file.url}.${file.extension}`,
				})),
			]);
		},
	});

	const processImages = (files: File[]) => {
		return Promise.all(files.map(processImage));
	};

	const processImage = async (file: File) => {
		const filename = v4();
		const extension = file.name.split('.').pop();
		const url = `${process.env.REACT_APP_S3_URL}/ads/display/${filename}`;
		let finalImage: File = file;

		if (file.type !== 'image/gif') {
			const image = await fromImage(file);
			finalImage = await image
				.type(file.type)
				.quality(0.8)
				.thumbnail(800)
				.toFile(`${filename}.${extension}`);
		}
		Object.assign(finalImage, {
			preview: URL.createObjectURL(finalImage),
			uploadStatus: UploadStatus.new,
			url,
			extension,
			filename,
		});

		return finalImage;
	};

	const sensors = useSensors(
		useSensor(MouseSensor, {
			activationConstraint: {
				distance: 10,
			},
		}),
		useSensor(TouchSensor, {
			activationConstraint: {
				distance: 10,
			},
		})
	);

	const handleDragEnd = (event: DragEndEvent) => {
		const { active, over } = event;
		const oldIndex = active.data.current?.sortable.index;
		const newIndex = over?.data.current?.sortable.index;

		if (typeof oldIndex === 'number' && typeof newIndex === 'number') {
			const newOrder = arrayMove(value, oldIndex, newIndex);
			onChange(newOrder);
		}
	};

	const removeImage = (url: string) => {
		onChange(value.filter((image) => image.url !== url));
	};

	return (
		<>
			<div className="col-span-6 mt-6">
				<div className="w-full mb-6">
					<h3 className="text-lg leading-6 font-medium text-gray-900">
						Upload display ads
					</h3>
					<p className="mt-1 text-sm text-gray-500">{description}</p>
				</div>
				<DndContext
					onDragEnd={handleDragEnd}
					collisionDetection={closestCorners}
					sensors={sensors}
				>
					<SortableContext items={value?.map((item) => item?.url) || []}>
						<DroppableContainer id="current-droppable">
							<div className="mt-1 sm:mt-0 col-span-1 aspect-w-3 aspect-h-2">
								<div
									{...getRootProps({ className: 'dropzone' })}
									className="flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md"
								>
									<div className="space-y-1 text-center self-center">
										<svg
											className="mx-auto h-12 w-12 text-gray-400"
											stroke="currentColor"
											fill="none"
											viewBox="0 0 48 48"
											aria-hidden="true"
										>
											<path
												d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
												strokeWidth={2}
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
										<div className="flex text-sm text-gray-600">
											<label
												htmlFor="file-upload"
												className="relative cursor-pointer font-medium text-primary-600 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
											>
												<span>Upload a file</span>
												<input {...getInputProps()} />
											</label>
											<p className="pl-1">or drag and drop</p>
										</div>
										<p className="text-xs text-gray-500">PNG, JPG, GIF</p>
									</div>
								</div>
							</div>

							{value?.map((file, i) => (
								<ImageUploaderImageSortable
									key={file.url}
									image={file}
									onClickDelete={removeImage}
									extraIcons={
										<PencilIcon
											className="absolute left-2 bottom-2 w-5 h-5 text-white cursor-pointer"
											onClick={() => {
												setOpenEditLinkModal(OpenClosedStates.Open);
												setEditingData({
													index: i,
													value: file.link || '',
												});
											}}
										/>
									}
								/>
							))}
						</DroppableContainer>
					</SortableContext>
				</DndContext>
				<FieldErrors errors={errors} />
			</div>
			<Modal state={openEditLinkModal} minHeight={250}>
				<div className="flex flex-col">
					<div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
						<LinkIcon className="h-6 w-6 text-gray-600" aria-hidden="true" />
					</div>
					<div className="mt-3 text-center sm:mt-5">
						<div className="text-lg leading-6 font-medium text-gray-900">
							Update link
						</div>
						<div className="mt-2 text-left">
							<Input
								name="link"
								value={editingData?.value}
								onChange={(val) =>
									setEditingData((prev) => ({
										index: prev?.index || 0,
										value: val,
									}))
								}
							/>
						</div>
					</div>

					<div className="mt-5 sm:mt-6 flex flex-row gap-6">
						<button
							type="button"
							className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:col-start-1 sm:text-sm"
							onClick={() => setOpenEditLinkModal(OpenClosedStates.Closed)}
						>
							Close
						</button>
						<button
							type="button"
							className="mt-3 w-full inline-flex justify-center rounded-md px-4 py-2 bg-primary-500 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:col-start-1 sm:text-sm"
							onClick={() => {
								onChange(
									value.map((file, i) => {
										if (i !== editingData?.index) {
											return file;
										}
										file.link = editingData?.value;
										return file;
									})
								);
								setOpenEditLinkModal(OpenClosedStates.Closed);
								setEditingData(null);
							}}
						>
							Save
						</button>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default AdImageUploader;
