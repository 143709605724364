import {
	closestCorners,
	DndContext,
	DragEndEvent,
	MouseSensor,
	TouchSensor,
	useDroppable,
	useSensor,
	useSensors,
} from '@dnd-kit/core';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import { fromImage } from '../common/helpers/Imtool.index';
import React, { useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import {
	Product,
	ProductImage,
	UploadStatus,
} from '../pages/products/Product.type';
import uploadService from '../services/upload.service';
import { MeContext } from './contexts/me.context';
import { ToastTypes } from './contexts/toast.context';
import { FieldErrors } from './form/FieldErrors';
import ImageUploaderImageSortable from './ImageUploaderImageSortable';
import { FormError } from './types/ErrorResponse.type';

function DroppableContainer({
	children,
	id,
}: {
	children: React.ReactNode;
	id: string;
	getStyle?: ({
		isOverContainer,
	}: {
		isOverContainer: boolean;
	}) => React.CSSProperties;
}) {
	const { setNodeRef } = useDroppable({
		id,
	});

	return (
		<div
			ref={setNodeRef}
			className="col-span-6 grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5 gap-4"
			style={{
				minHeight: 110,
			}}
		>
			{children}
		</div>
	);
}

type Props = {
	value: ProductImage[];
	onChange: (val: ProductImage[]) => void;
	prefix?: string;
	errors?: FormError;
	disabled?: boolean;
	createToast?: (message: string, type: ToastTypes) => void;
	product: Partial<Product>;
	dealerInfo: Partial<any>;
};

const ImageUploader: React.FC<Props> = ({
	value,
	onChange,
	prefix,
	errors,
	disabled,
	createToast,
	product,
	dealerInfo,
}) => {
	const { me } = useContext(MeContext);
	const { getRootProps, getInputProps } = useDropzone({
		accept: {
			'image/*': [],
		},
		maxFiles: 1,
		multiple: false,
		onDrop: async (acceptedFiles: File[]) => {
			if (disabled && createToast) {
				createToast('Image limit reached for listing.', ToastTypes.Fail);
				return;
			}
			const images = acceptedFiles.filter((file) =>
				file.type.startsWith('image/')
			);
			let imagesResized: any[] = [];
			if (images?.length) {
				imagesResized = await processImages(images);
				onChange([...value, ...imagesResized]);
				for (const file of imagesResized) {
					try {
						const res = await uploadService.upload(
							me?.account.id,
							file,
							'image/jpeg',
							false,
							'',
							undefined,
							value?.length
						);
						if (res === 'limitReached' && createToast) {
							createToast('Image limit reached for listing.', ToastTypes.Fail);
						} else {
							file.url = res;
						}
						file.uploadStatus = UploadStatus.complete;
					} catch (err) {
						console.log(err);
						file.uploadStatus = UploadStatus.failed;
					}
				}
			}
			onChange([
				...value,
				...imagesResized.map((file) => ({
					...file,
					url: file.url,
				})),
			]);
		},
	});

	const processImages = (files: File[]) => {
		return Promise.all(files.map(processImage));
	};

	const processImage = async (file: File) => {
		const image = await fromImage(file);
		let filename = ``;
		let baseFileName = `${dealerInfo.path}`;
		if (dealerInfo?.name) {
			const dealer = dealerInfo.name
				.replace(/[^\w\s]/gi, '')
				.toLowerCase()
				.split(' ')
				.filter((strPart: string) => strPart.length)
				.join('-');
			filename = `${dealer}-`;
		}
		if (product.location) {
			const location = dealerInfo?.locations.find(
				(loc: any) => loc.id === product.location
			);
			const city = location.city
				.replace(/[^\w\s]/gi, '')
				.toLowerCase()
				.split(' ')
				.filter((strPart: string) => strPart.length)
				.join('-');
			const state = location.state;
			filename = `${filename}${city}-${state}`;
			baseFileName = filename;
		} else {
			filename = `${dealerInfo.path}`;
		}
		if (product.style?.[0]) {
			filename = `${filename}-${product.style[0].toLowerCase()}`;
		}
		if (product.width) {
			let size = `${product.width}`;
			if (product.length) {
				size = `${size}X${product.length}`;
			}
			filename = `${filename}-${size}`;
		}
		if (product.colorMain) {
			filename = `${filename}-${product.colorMain.toLowerCase()}`;
		}
		if (filename === baseFileName) {
			filename = `${filename}-product-image`;
		}
		if (prefix) {
			filename = `${prefix}-${filename}`;
		}
		const resizedImage: any = await image
			.type(file.type)
			.quality(1)
			.thumbnail(1600)
			.toFile(`${filename}`);
		Object.assign(resizedImage, {
			preview: URL.createObjectURL(resizedImage),
			uploadStatus: UploadStatus.new,
			url: `${process.env.REACT_APP_S3_URL}/accounts/${me?.account.id}/${filename}`,
			extension: file.name.split('.').pop(),
			filename,
		});
		return resizedImage;
	};

	const sensors = useSensors(
		useSensor(MouseSensor, {
			activationConstraint: {
				distance: 10,
			},
		}),
		useSensor(TouchSensor, {
			activationConstraint: {
				distance: 10,
			},
		})
	);

	const handleDragEnd = (event: DragEndEvent) => {
		const { active, over } = event;
		const oldIndex = active.data.current?.sortable.index;
		const newIndex = over?.data.current?.sortable.index;

		if (typeof oldIndex === 'number' && typeof newIndex === 'number') {
			const newOrder = arrayMove(value, oldIndex, newIndex);
			onChange(newOrder);
		}
	};

	const removeImage = (url: string) => {
		onChange(value.filter((image) => image.url !== url));
	};

	return (
		<div className="col-span-6 mt-6">
			<div className="w-full mb-6">
				<h3 className="text-lg leading-6 font-medium text-gray-900">Photos</h3>
				<p className="mt-1 text-sm text-gray-500">
					{!me.account.subscription?.options?.unlimitedImages
						? 'You may upload up to 1 image per listing. Please upgrade your membership to add unlimited images if you would like to add more images to your listings.'
						: 'You are currently subscribed to the unlimited images plan. To upload an image, you may click on "Upload a file" or drag and drop a file into the box. You can upload as many images as you want, however you may only add one at a time.'}
				</p>
			</div>
			<DndContext
				onDragEnd={handleDragEnd}
				collisionDetection={closestCorners}
				sensors={sensors}
			>
				<SortableContext items={value?.map((item) => item?.url) || []}>
					<DroppableContainer id="current-droppable">
						<div className="mt-1 sm:mt-0 col-span-1 aspect-w-3 aspect-h-2">
							<div
								{...getRootProps({ className: 'dropzone' })}
								className="flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md"
							>
								<div className="space-y-1 text-center self-center">
									<svg
										className="mx-auto h-12 w-12 text-gray-400"
										stroke="currentColor"
										fill="none"
										viewBox="0 0 48 48"
										aria-hidden="true"
									>
										<path
											d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
											strokeWidth={2}
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
									<div className="flex text-sm text-gray-600" data-cy="upload">
										<label
											htmlFor="file-upload"
											className={[
												'relative cursor-pointer font-medium focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2',
												disabled
													? 'text-gray-500 hover:text-gray-400 focus-within:ring-gray-500 cursor-not-allowed'
													: 'text-primary-600 hover:text-primary-500 focus-within:ring-primary-500',
											].join(' ')}
										>
											<span>Upload a file</span>
											<input {...getInputProps()} disabled={disabled} />
										</label>
										<p className="pl-1">or drag and drop</p>
									</div>
									<p className="text-xs text-gray-500">PNG, JPG, GIF</p>
								</div>
							</div>
						</div>

						{value?.map((file, i) => (
							<ImageUploaderImageSortable
								key={file.url}
								image={file}
								onClickDelete={removeImage}
							/>
						))}
					</DroppableContainer>
				</SortableContext>
			</DndContext>
			<FieldErrors errors={errors} />
		</div>
	);
};

export default ImageUploader;
