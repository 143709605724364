import React from 'react';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/outline';
import { Checkbox } from '../../common/form/Checkbox';

interface Props {
  orderDesc: boolean;
  setOrderDesc: React.Dispatch<React.SetStateAction<boolean>>;
  reOrder: (order: 'asc' | 'desc') => void;
  searchTerm: string;
  filterContacted: (shouldFilter: boolean) => void;
}

const ContactListHeader: React.FC<Props> = ({
  orderDesc,
  setOrderDesc,
  reOrder,
  searchTerm,
  filterContacted,
}) => {
  return (
    <div className="flex flex-col gap-1 items-end">
      <div
        onClick={() => {
          reOrder(orderDesc ? 'asc' : 'desc');
          setOrderDesc((prev) => !prev);
        }}
      >
        <span className="cursor-pointer text-gray-500 hover:text-gray-600">
          {orderDesc ? (
            <div className="flex gap-1 justify-center items-center">
              <span className="text-sm">Oldest first</span>
              <ArrowUpIcon className="h-5 w-5" />
            </div>
          ) : (
            <div className="flex gap-1 justify-center items-center">
              <span className="text-sm">Newest first</span>
              <ArrowDownIcon className="h-5 w-5" />
            </div>
          )}
        </span>
      </div>
      <div className="flex gap-2 space-between items-center">
        <span className="text-sm text-gray-500">Not contacted</span>
        <Checkbox
          value={searchTerm === 'contacted'}
          onChange={() => filterContacted(searchTerm !== 'contacted')}
        />
      </div>
    </div>
  );
};

export default ContactListHeader;
