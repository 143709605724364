import React, { useState } from 'react';
import { Contact } from '../../types/Contact.type';
import useContacts from './hooks/useContacts';
import Header from '../../common/Header';
import List from '../../common/list/List';
import ContactListHeader from './ContactListHeader';
import ContactItem from './ContactItem';
import ContactModal from './ContactModal';

const ContactList: React.FC = () => {
  const {
    data,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    markAsContacted,
    reOrder,
    filterContacted,
    searchTerm = '',
    refetch,
  } = useContacts();

  const [selectedContact, setSelectedContact] = useState<Contact | null>(null);
  const [markingAsContacted, setMarkingAsContacted] = useState<number | undefined>();
  const [orderDesc, setOrderDesc] = useState<boolean>(true);

  const closeModal = () => {
    setSelectedContact(null);
  };

  const markContacted = async (contactId: number) => {
    setMarkingAsContacted(contactId);
    await markAsContacted(contactId);
    setMarkingAsContacted(undefined);
    refetch();
  };

  return (
    <>
      <Header
        title="Contacts"
        className="pb-6"
        rightSide={<ContactListHeader
          orderDesc={orderDesc}
          setOrderDesc={setOrderDesc}
          reOrder={reOrder}
          searchTerm={searchTerm}
          filterContacted={filterContacted}
        />}
      />
      <List
        isFetching={isFetching}
        isFetchingNextPage={!!isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
      >
        {data?.pages?.map((page, i: number) => (
          <React.Fragment key={i}>
            {page?.data?.map((row: Contact, rowKey: number) => (
              <ContactItem
                key={row.id}
                row={row}
                rowKey={rowKey}
                setSelectedContact={setSelectedContact} 
                markContacted={markContacted}
                markingAsContacted={markingAsContacted}
              />
            ))}
          </React.Fragment>
        ))}
      </List>
      {selectedContact && (
        <ContactModal contact={selectedContact} close={closeModal} />
      )}
    </>
  );
};

export default ContactList;
